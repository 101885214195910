<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>{{ $t('desconto') }}</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'descontoForm', params: {id: 0} }"
                        >
                            {{ $t('novo') }} {{ $t('desconto') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('novo') }} {{ $t('desconto') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:item.statusDescricao="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescricao }}
                        </v-chip>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'descontoForm'"
                            :showButtons="{
                                edit: true,
                                delete: true
                            }"
                            @confirmDelete="confirmDelete"/>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,
            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: vue.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                { text: vue.$t('tipoCliente'), value: "tipoClienteDescricao", sortable: true },
                { text: vue.$t('marca'), value: "descricaoMarca", sortable: true },
                { text: vue.$t('familia'), value: "descricaoProduto", sortable: true, width: "40%" },
                { text: vue.$t('descontoBase'), value: "descontoBaseFormatted", sortable: true, align: "center" },
                { text: vue.$t('aPartirQuantasPecas'), value: "qtdePecasLimite", sortable: true, align: "center" },
                { text: vue.$t('desconto'), value: "descontoFormatted", sortable: true, align: "center" },
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listDescontos: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listDescontos.filter((descontoFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dataCadastroFormatted = descontoFilter.dataCadastroFormatted.toLowerCase().match(filter)
                    var tipoClienteDescricao = descontoFilter.tipoClienteDescricao.toLowerCase().match(filter)
                    var descricaoTipoProduto = descontoFilter.descricaoTipoProduto.toLowerCase().match(filter)
                    var descricaoMarca = descontoFilter.descricaoMarca.toLowerCase().match(filter)
                    var descricaoProduto = descontoFilter.descricaoProduto.toLowerCase().match(filter)
                    var descontoBaseFormatted = descontoFilter.descontoBaseFormatted.toString().toLowerCase().match(filter)
                    var qtdePecasLimite = descontoFilter.qtdePecasLimite.toString().toLowerCase().match(filter)
                    var descontoFormatted = descontoFilter.descontoFormatted.toString().toLowerCase().match(filter)
                    var id = descontoFilter.id.toString().match(filter);

                    if(dataCadastroFormatted != null) { return dataCadastroFormatted; }
                    if(tipoClienteDescricao != null) { return tipoClienteDescricao; }
                    if(descricaoTipoProduto != null) { return descricaoTipoProduto; }
                    if(descricaoMarca != null) { return descricaoMarca; }
                    if(descricaoProduto != null) { return descricaoProduto; }
                    if(descontoBaseFormatted != null) { return descontoBaseFormatted; }
                    if(qtdePecasLimite != null) { return qtdePecasLimite; }
                    if(descontoFormatted != null) { return descontoFormatted; }
                    else { return id; }
                });
            }
        },

        methods: {

            async getRegisters() {

                this.listDescontos = await this.$store.dispatch("descontoModule/List");
            },

            confirmDelete(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaExclusaoDesconto'),
                    methodConfirm: this.delete,
                    params: id
                };

            },

            async delete(id) {

                this.showLoading();

                const result = await this.$store.dispatch("descontoModule/Delete", id);

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                await this.getRegisters();

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
